<template>
  <card card-body-classes="table-full-width">
    <div class="row">
      <div class="col-12">
        <h4 slot="header" class="card-title">LISTA DE E-MAIL</h4>
      </div>
      <div class="col-12 mt-3 d-flex align-items-center justify-content-center justify-content-sm-between flex-wrap">
        <el-select class="select-primary pagination-select" v-model="pagination.perPage" placeholder="Por página">
          <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item"></el-option>
        </el-select>
        <div class="d-flex align-items-center">
          <el-input type="search" prefix-icon="el-icon-search" placeholder="Procurar e-mail" v-model="searchQuery" aria-controls="datatables"></el-input>
        </div>
      </div>
      <div class="col-12 mt-3">
        <el-table :data="queriedData.filter(data => !searchQuery || data.description.toLowerCase().includes(searchQuery.toLowerCase()))" v-if="total > 0">
          <el-table-column v-for="column in tableColumns" :key="column.label" :width="column.width" :prop="column.prop" :label="column.label"></el-table-column>
          <el-table-column width="108" v-if="total > 0" align="center" label="Ações">
            <template v-slot="scope">
              <el-tooltip class="item" :content="scope.row.status" placement="top">
                <el-button :type="scope.row.status==='Ativo'?'success':'info'" :icon="scope.row.status==='Ativo'?'tim-icons icon-check-2':'tim-icons icon-simple-remove'" plain circle @click="handleDisable(scope.$index, scope.row)" size="small"></el-button>
              </el-tooltip>
              <el-button type="warning" icon="tim-icons icon-pencil" plain circle @click="edit(scope.$index, scope.row)" size="small"></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="col-12 mt-3 d-flex justify-content-center justify-content-sm-between flex-wrap">
        <div>
          <p class="card-category table-footer" v-if="total > 0">
            Exibindo de {{ from + 1 }} à {{ to }} de {{ total }} registros
          </p>
          <p class="card-category table-footer" v-if="total === 0">
            Nenhum registro encontrado.
          </p>
        </div>
        <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="total" v-if="total > 0"></base-pagination>
      </div>
    </div>
  </card>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert2'
import { BasePagination } from '@/components'
export default {
  name: 'index',
  components: {
    BasePagination
  },
  data () {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
        total: 0
      },
      searchQuery: '',
      searchedData: [],
      tableData: [],
      tableColumns: [
        {
          prop: 'description',
          label: 'Descrição',
          align: 'left'
        },
        {
          prop: 'status',
          label: 'Status',
          align: 'center',
          width: '120'
        }
      ]
    }
  },
  async mounted () {
    await this.getTemplates()
  },
  methods: {
    async getTemplates () {
      this.tableData = []
      const response = await axios.get('/emailtemplate/', {
        headers: {
          Authorization: this.$jwt.getAccessToken()
        }
      })

      if (response.data) {
        response.data.forEach((item, index) => {
          this.tableData.push({
            id: item.id,
            description: item.description,
            status: item.enabled ? 'Ativo' : 'Inativo'
          })
        })
      }
    },
    edit (index, row) {
      return this.$router.push(`/email/${row.id}`)
    },
    handleDisable (index, row) {
      swal.fire({
        title: 'Você tem certeza?',
        text: row.status === 'Ativo' ? `Você está prestes a desativar "${row.description}".` : `Você está prestes a ativar "${row.description}".`,
        width: 600,
        showCancelButton: true,
        confirmButtonText: row.status === 'Ativo' ? 'Sim, eu quero desativar!' : 'Sim, eu quero ativar!',
        cancelButtonText: 'Cancelar'
      }).then(result => {
        if (result.value) {
          swal.fire({
            title: row.status === 'Ativo' ? 'Desativando' : 'Ativando',
            text: row.status === 'Ativo' ? `Aguarde, estamos desativando "${row.description}".` : `Aguarde, estamos ativando "${row.description}".`,
            showConfirmButton: false,
            timer: 1000,
            onBeforeOpen: () => {
              swal.showLoading()
            }
          })
          this.disableRow(row)
        }
      })
    },
    async disableRow (row) {
      const response = await axios.post('/emailtemplate/', {
        id: row.id,
        enabled: row.status === 'Ativo' ? 0 : 1
      }, {
        headers: {
          Authorization: this.$jwt.getAccessToken(),
          'Content-Type': 'application/json'
        }
      })

      if (response && response.status === 200) {
        await swal.fire({
          title: row.status === 'Ativo' ? 'Desativado!' : 'Ativado!',
          text: row.status === 'Ativo' ? `Você desativou "${row.description}"` : `Você ativou "${row.description}"`,
          showConfirmButton: false,
          timer: 1000
        })
        await this.getTemplates()
      } else {
        await swal.fire({
          title: 'Error!',
          text: row.status === 'Ativo' ? `Ocorreu um erro ao desativar "${row.description}"` : `Ocorreu um erro ao ativar "${row.description}"`,
          denyButtonText: 'Ok',
          showDenyButton: true,
          showConfirmButton: false
        })
      }
    }
  },
  computed: {
    queriedData () {
      let result = this.tableData
      if (this.searchedData.length > 0) {
        result = this.searchedData
      }
      return result.slice(this.from, this.to)
    },
    total () {
      return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    }
  }
}
</script>
